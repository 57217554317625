import React from "react"
import { graphql, Link } from "gatsby"

import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import logo from "../images/product/logo.svg"

import { Container } from "../components/global"

export default function FeaturedPage({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <Navigation />
      <FeaturedPageContainer>
        <div>
          <h1>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <img
          src={"/static/" + post.frontmatter.photo}
          alt={post.frontmatter.alt}
          style={{ maxWidth: "350px" }}
        ></img>
        <Pricing>{post.frontmatter.pricing}</Pricing>
        <br /> <br />
        <div>
          <Link to="/">Return to Homepage</Link>
        </div>
      </FeaturedPageContainer>
      <BrandContainer>
        <Logo>
          <ImageWrapper>
            <img src={logo} alt="Logo" style={{ width: "40px" }} />
          </ImageWrapper>
          TheRhetoricians.com
        </Logo>
      </BrandContainer>
    </Layout>
  )
}

const FeaturedPageContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`

const ImageWrapper = styled.div`
  display: inline;
`

const Pricing = styled.div`
  display: flex;
  justify-content: center;
  font-style: italic;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.crimsontext};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        pricing
        photo
      }
    }
  }
`
